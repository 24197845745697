export default {
  siteTitle: 'Get bonus',
  blogerName: 'CARLLYBET',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://youtube.com/@carllibets'
    },
    {
      name: 'instagram',
      url: 'https://instagram.com/carllybet?igshid=YmMyMTA2M2Y='
    },
    {
      name: 'instagram',
      url: 'https://instagram.com/carllianaleite?igshid=YmMyMTA2M2Y='
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irrs.com/c9bde53c3',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-blrs.com/c016adba9',
      gameTitle: 'Savage Buffalo Spirit Megaways (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/cf02b5ec9',
      gameTitle: 'JET AIR (BGaming)',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs12.com/caf08923f',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/cdf33e6d0',
      gameTitle: 'Izzi Art (BGaming)', // имя игры изменено. копировать внимательно!
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/cb484a31b',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c2f026c5c',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/c7e644256',
      gameTitle: 'Starda Queen (BGaming)', // имя игры изменено. копировать внимательно!
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/c492f0849',
      gameTitle: 'Legzo Punk (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>CARLLYBET</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'CARLLYBET',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'brazil'
};
